import React, {useState} from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import Helmet from "react-helmet"
import GlossaryNameListingModule from '../components/GlossaryNameListingModule';
import { number } from 'prop-types';

export const query = graphql`
    query glossaries {
        wpcontent {
            glossaries(first: 99 where: {orderby: {field: TITLE, order: ASC}}) {
            nodes {
                id
                title
                uri
                excerpt
            }
            }
            seo {
            contentTypes {
                glossary {
                metaDesc
                title
                }
            }
            }
        }
    }
`

const Glossaries = ({ data }) => {

    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    const glossaries = data.wpcontent.glossaries.nodes
    const glossaryTitle = data.wpcontent.glossaries.nodes.title
    const glossarySeo = data.wpcontent.seo.contentTypes.glossary
    const pageTitle = glossarySeo.title
    const metaDesc = glossarySeo.metaDesc

    console.log("pageTitle",pageTitle)

  return (
    <Layout>
        <Helmet>
            <title>HR Glossaries and Workplace Terms | Vantage Circle</title>

            <meta name="description" content="Empower your organization with our curated employee engagement / HR glossary. Enhance communication and understanding across all levels." />

            <meta property="og:title" content="HR Glossaries and Workplace Terms | Vantage Circle" />
g
            <meta property="og:description" content="Empower your organization with our curated employee engagement / HR glossary. Enhance communication and understanding across all levels." />

            <meta property="twitter:title" content="HR Glossary & Workplace Terms | Vantage Circle" />
                    
            <meta property="twitter:description" content="Empower your organization with our curated employee engagement / HR glossary. Enhance communication and understanding across all levels." />
                    
            <meta property="og:type" content="website"/>
                    
            <meta property="og:url" content="https://www.vantagecircle.com/glossaries/"/>

            <meta property="og:site_name" content="Vantage Circle"/>
            <link rel="canonical" href="https://www.vantagecircle.com/glossaries/"></link>
        </Helmet>

        <section className='w-11/12 xl:w-full xl:max-w-7xl mx-auto mt-10'>
            <div className='bg-indigo-100 p-5 grid place-content-center rounded-xl my-10 py-10'>
                <h1 className='homepage-heading text-center text-purple-100 mb-10'>HR Glossary & Workplace Terms</h1>
                {/* <h1 className='homepage-heading text-center text-purple-100 mb-5'>Understanding Workplace Terms</h1> */}
                <p className='text-center text-xl text-purple-100 max-w-5xl'>Our HR Glossary is a comprehensive resource offering insights into employee engagement, workplace culture, 
                benefits, recognition and reward, management practices, and more.</p>   
            </div>

            {/* FOR POINTING TO CORRECT LOCATION IN DOCUMENT */}
            <div className='max-w-2xl flex place-content-center mx-auto flex-wrap'>  
                <div className='bg-gray-150 flex place-content-center rounded h-10 w-10 border m-1'><a href="#numbers" className='m-0 p-0 my-auto text-gray-500'>#</a></div>
                
                {alphabet.split('').map(letter => (
                    <div className='bg-gray-150 flex place-content-center rounded h-10 w-10 border m-1'><a href={`#${letter}`} className='m-0 p-0 my-auto text-gray-500'>{letter}</a></div>
                ))}
            </div>

            {/* FOR GLOSSARY TITLES STARTING WITH NUMBERS - GOES TO # SECTION */}
            <div className='mt-20'>
                <h2 className='text-3xl pb-3 border-b w-1/3' id="numbers">#</h2>
                <div className='md:grid md:grid-cols-2 xl:grid-cols-3 mt-5'>
                    {
                        glossaries.map(glossary => {  
                            
                            if(/[0-9]/.test(glossary.title.charAt(0)) ){
                                return(
                                    <ul className='purplebullets'>
                                        <li className=''>
                                            <a href={glossary.uri} className='homepage-mini-heading text-indigo-100'>{glossary.title}</a>
                                        </li>
                                    </ul>
                                )
                            }
                        })
                    }
                </div>
            </div>

            {/* FOR GLOSSARY TITLES STARTING WITH ALPHABETS - GOES FROM A-Z SECTION */}
            {alphabet.split('').map(letter => (
                <GlossaryNameListingModule key={letter} glossaries={glossaries} startingLetter={letter} />
            ))}
        </section>
    </Layout>
  )
}

export default Glossaries